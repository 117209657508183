<template>
    <el-table v-if="carsData.length > 0" :data="carsData">
        <el-table-column prop="name" :label="$t('cars.name')" width="200" />
        <el-table-column prop="license_plate" :label="$t('cars.license_plate')" width="180" />
        <el-table-column prop="note" :label="$t('cars.note')" show-overflow-tooltip />
        <el-table-column width="100">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.details')" placement="left">
                        <el-button size="mini" type="primary" @click="handleInfo(scope.row.id)">
                            <fa-icon :icon="['fas', 'info']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.restore')" placement="top">
                        <el-button :loading="$wait.is(`loading.restore${scope.row.id}`)" size="mini" type="warning" @click="handleRestore(scope.row.id)">
                            <fa-icon v-if="!$wait.is(`loading.restore${scope.row.id}`)" :icon="['fas', 'redo']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.delete')" placement="top">
                        <el-button type="danger" size="mini" @click="handleDelete(scope.row.id)">
                            <fa-icon :icon="['fas', 'trash']" fixed-width />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
export default {
    props: {
        carsData: {
            type:    Array,
            default: () => [],
        },
    },

    methods: {
        handleInfo(id) {
            this.$emit('handleInfo', id);
        },

        handleRestore(id) {
            this.$emit('handleRestore', id);
        },

        handleDelete(id) {
            this.$emit('handleDelete', id);
        },
    },
};
</script>
